import './App.css';
import React, { useEffect } from "react";
import aboutpagehead from "./img/aboutpagehead.jpg"
import websitedesign from "./img/website-design-and-development.jpg"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navigation from './Navigation';
import Footer from './Footer';

function About() {
  useEffect(() => {
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)
  }, []);
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="About">
      <Navigation/>
      <div className='aboutus'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 abouts-heads-contents'>
              <div className='about-head-content'>
                <h1 data-aos="fade-right" data-aos-duration="1500">About us</h1>
                <h3 data-aos="fade-up" data-aos-duration="1500">Web Design Company in Chennai</h3>
                <p data-aos="fade-down" data-aos-duration="1500">We provide our services at a market price with high-quality worth the value spent and we are the pioneers of modern Web Development Standards.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 '>
              <div className='about-head-img'>
                <img data-aos="zoom-out" data-aos-duration="1500" src={aboutpagehead} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='aboutsection2'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 abt-img-1s '>
              <div className='abt-img-1'>
                <img data-aos="fade-right" data-aos-duration="1500" src={websitedesign} />
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 d-flex'>
              <div className='abt-content' data-aos="fade-left" data-aos-duration="1500">
                <h2>
                  ABOUT US
                </h2>
                <p>We Eximho Web Solutions., a Part Of Clemence Group of Companies is Web Design and Development , Apps Developing Company in Chennai, India. Firstly We are Young and Smarter Team with 5+ Years of Industrial Experience will help you to Support all your Web needs. Importantly We Offer Amazing Website Designs. Innovative Web-based Solutions that will help you keep your Clients engaged. Then We Provide Professional and Best-Web Design Service Globally. However We Offer Web Based Services like Web Design and Development, Apps Development, Digital Marketing, SEO and SEM Works, Logo and Branding by Worldwide. Professionally We are Dedicated Team Players Committed to Provide Professional Designs with latest Technologies in trends. As a Result we provide a Quality and the Web Designs Company in Chennai. We are 100% Customer Oriented, and focusing Customer Satisfaction is one of Our Brand Value.</p>
              </div>
            </div>
          </div>
          <div className="aboutsection2-content">
            <p>Our web design and development services include everything from designing and developing a brand new website from scratch to revamping an existing one. We specialize in creating responsive websites that look great on any device, whether it’s a desktop computer, laptop, tablet, or smartphone.</p>
            <p>Our web development services also include e-commerce solutions, custom web applications, and content management systems. We can help you create a robust online store that will drive sales and revenue or develop a custom web application that will streamline your business processes and increase efficiency.</p>
            <p>At our web design and development company, we understand that every business is unique, and we take a customized approach to every project we undertake. Our team will work closely with you to understand your business objectives and design a website that aligns with your goals.</p>
            <p>We use the latest web design and development technologies to ensure that your website is fast, secure, and optimized for search engines. Our team of SEO experts will also help you create content that is optimized for search engines and will help your website rank higher in search engine results pages.</p>
            <p>So if you’re looking for a reliable and experienced web design and development company, look no further! Contact us today to learn more about our services and how we can help you take your business to the next level.</p>
          </div>
        </div>
      </div>
      <div className="what-our-clients-say">
        <div className="container">
          <h2>WHAT OUR CLIENTS SAY</h2>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
            <i class="fa-solid fa-quote-right"></i>
              <div className="cards">
                <p>I contacted them for developing my interior designing business website. They created an amazing website. Very attractive as well as professional which exceeded my expectations.</p>
                <h6>Kumaran</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
            <i class="fa-solid fa-quote-right"></i>
              <div className="cards">
                <p>Excellent team and can deliver my Website on time quality is good and pricing very competitive, I would recommend highly for Web Development Service.</p>
                <h6>Rajesh Kumar</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
            <i class="fa-solid fa-quote-right"></i>
              <div className="cards">
                <p>Clemence team understands our requirement clearly and our website was developed on time. Web Design team is very friendly to communicate.</p>
                <h6>Dinakaran</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
            <i class="fa-solid fa-quote-right"></i>
              <div className="cards">
                <p>This was the first time I developed a website so I couldn’t have asked for a better or easier group to work with. The entire process from sales through development and launch was timely and extremely efficient. A fantastic experience all around!</p>
                <h6>Sabastian</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
            <i class="fa-solid fa-quote-right"></i>
              <div className="cards">
                <p>I had a startup business idea but I didn’t know how to start and promote it in the market until I met Clemence Web Solutions. They have got an amazing team, who guided me from the very beginning. It has been an absolute pleasure working with them.</p>
                <h6>Vijaykumar</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
            <i class="fa-solid fa-quote-right"></i>
              <div className="cards">
                <p>When its comes creating professional, attractive, and effective website Clemence Web Solutions Gave me a 100% satisfaction. Such a nice company with a clients supportive environment. i got everything in my site which I dreamed.</p>
                <h6>Selvaraj</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div >
  );
}
export default About;
