import "./App.css";
import React, { useEffect } from "react";
import serviceshome from "./img/serviceshead.jpg";
import ser1 from "./img/ser-1.png";
import ser2 from "./img/ser-2.png";
import ser3 from "./img/ser-3.png";
import ser4 from "./img/ser-4.png";
import ser5 from "./img/ser-5.png";
import ser6 from "./img/ser-6.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Navigation from "./Navigation";
import Footer from "./Footer";

function Services() {
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="Servces">
      <Navigation />
      <div className="services-home">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="services-content">
                <div
                  className="about-head-content"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <h1>Our Services</h1>
                  <h3>
                    Technologically,Aesthetically and Expressively Rich
                    Solutions.
                  </h3>
                  <p>
                    We are media professionals, graphic designers and web
                    developers with many years of experience, sound technical
                    knowledge and a feeling for style and style! Our core
                    competencies range from design and development to the
                    production of advertising material and printed material.
                    Here is a summary of our services.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 servicess-imgs">
              <div
                className="services-img"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <img src={serviceshome} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="head">
                <h2 data-aos="fade-right" data-aos-duration="1500">
                  Services
                </h2>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div
                className="para"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <p>
                  We are media professionals, graphic designers and web
                  developers with many years of experience, sound technical
                  knowledge and a feeling for style and style! Our Core
                  competencies range from design and development to the
                  production of advertising material and Media.
                </p>
              </div>
            </div>
          </div>
          <div className="designrow">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="card"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <img src={ser1} />
                  <h2>BRANDING</h2>
                  <p>
                    Make your brand stand out of the corporate crowd. Blow off
                    some steam and take our expertise in building an identity
                    for you.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src={ser2} />

                  <h2>UI DESIGN</h2>
                  <p>
                    UX is driven by UI. Our bunch of UI designers at Quantzi
                    have vast experience and expertise in the vital areas of
                    user experience.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="card"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <img src={ser3} />

                  <h2>UI DEVELOPMENT SOLUTIONS</h2>
                  <p>
                    At Quantzi, we are well aware of the importance of UI and
                    its effect on the UX. Needless to say that UI decides the
                    future of the app.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="card"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <img src={ser4} />

                  <h2>WEB DEVELOPMENT</h2>
                  <p>
                    We Just Don’t Code Websites, We Code Your World A Bunch of
                    Cleverest Programmers At Your Service!
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="card"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src={ser5} />

                  <h2>APP DEVELOPMENT</h2>
                  <p>
                    Every app is different and needs special attention. We have
                    learned this by working for various domains and numerous
                    projects.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="card"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <img src={ser6} />

                  <h2>DIGITAL MARKETING</h2>
                  <p>
                    If your Decision is the fire, our strategy is the fuel
                    measured in smiles per hour!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <h5>Frequently Asked Questions</h5>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What is the difference between web design and development?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Website development service and design are two distinct
                  processes. While website development service involves writing
                  code to provide functions and features on a website, website
                  design focuses on designing the layout of a website and
                  producing visually appealing content. While web developers
                  concentrate on functionality, website designers prioritise
                  aesthetics and usability. Designers utilise programmes like
                  Photoshop and Illustrator to make the user experience visually
                  appealing, and programmers use languages like HTML, CSS,
                  JavaScript, and PHP to make dynamic websites that communicate
                  with databases. Web development focuses on how things
                  function, whereas web design focuses on how things look.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  What is a front-end website developer?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  A front-end developer in a good development agency has the
                  primary duty to make sure that users of the website can easily
                  interact with the page. They accomplish this by combining
                  programming, design, and technology to code a website’s
                  aesthetic and take care of debugging. The part of websites and
                  online applications that users actually view and interact with
                  is built by front-end developers. Using web languages like
                  HTML, CSS, and JavaScript, a front-end developer develops
                  websites and applications that people can access and utilise.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What is B2B website development?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  The process of creating a business-to-business (B2B) web
                  development company in India is referred to as B2B web
                  development. E-commerce capabilities, database integration,
                  and other features can be included in B2B web development. The
                  objective is to develop a website that makes communication and
                  business transactions between the two parties easy and
                  effective. In order to support intricate interactions between
                  the client and supplier, B2B websites are often built to be
                  highly functional and to be complex enough to demand extensive
                  programming abilities. This can include automated ordering
                  systems, tools for processing payments, systems for managing
                  customers, online businesses, and more.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What are CMS of a web development company?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  As it enables users to manage the information that displays on
                  their website without needing to know HTML code, a Content
                  Management System (CMS) is a potent tool for website
                  development. Without any technical expertise, a user can
                  easily update the material, change layouts and design
                  elements, add new pages and categories, and do much more with
                  CMS software. This is a fantastic method for anyone with no
                  technical knowledge to be in charge of all the many elements
                  of their website. There are many different CMS systems
                  available, from straightforward template-based solutions to
                  more intricate ones requiring substantial system
                  administration or utilising databases. Your individual needs
                  will determine the option you select.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What is B2B website development?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  The process of creating a business-to-business (B2B) web
                  development company in India is referred to as B2B web
                  development. E-commerce capabilities, database integration,
                  and other features can be included in B2B web development. The
                  objective is to develop a website that makes communication and
                  business transactions between the two parties easy and
                  effective. In order to support intricate interactions between
                  the client and supplier, B2B websites are often built to be
                  highly functional and to be complex enough to demand extensive
                  programming abilities. This can include automated ordering
                  systems, tools for processing payments, systems for managing
                  customers, online businesses, and more.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What is B2B website development?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  The process of creating a business-to-business (B2B) web
                  development company in India is referred to as B2B web
                  development. E-commerce capabilities, database integration,
                  and other features can be included in B2B web development. The
                  objective is to develop a website that makes communication and
                  business transactions between the two parties easy and
                  effective. In order to support intricate interactions between
                  the client and supplier, B2B websites are often built to be
                  highly functional and to be complex enough to demand extensive
                  programming abilities. This can include automated ordering
                  systems, tools for processing payments, systems for managing
                  customers, online businesses, and more.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Services;
