import './App.css';
import React from "react";
import Homepagelogo from "./images/1604918814639.png"
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className="Footer-Head">
            <footer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <div className='footer-1'>
                                <img src={Homepagelogo} />
                                {/* <h1>Eximho Pvt Ltd</h1> */}
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='footer-2'>
                                <h2>Quick Link</h2>
                                <h3><Link to='/About.js'>About</Link></h3>
                                <h3><Link to='/Services.js'>Services</Link></h3>
                                <h3><Link to='/Library.js'>Library  </Link></h3>
                                <h3><Link to='/Library.js'>Contact US</Link></h3>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='footer-2 footer-3'>
                                <h2>Address</h2>
                                <p>  Eximho Private Limited<br />
                                    No.36,1st Floor,<br />
                                    Medavakkam Main Road,<br />
                                    Madipakkam, Chennai - 600091<br />
                                    Near Madipakkam Kumaran Theatre
                                </p>

                                <a href="mailto:"><i class="fa-solid fa-envelope"></i>eximhoprivateltd@gmail.com</a>
                                <p><a href="tel:"><i class="fa-solid fa-phone"></i>93800 93808</a></p>
                            </div>
                        </div>
                    </div>

                    <div class="Social-media">
                        <a href=''><i class="fa-brands fa-instagram"></i></a>
                        <a href=''><i class="fa-brands fa-square-facebook"></i></a>
                        <a href=''><i class="fa-brands fa-square-twitter"></i></a>
                        <a href=''><i class="fa-brands fa-linkedin"></i></a>
                        <a href=''><i class="fa-brands fa-youtube"></i></a>
                    </div>
                    <hr />
                    <h6 className='copyright'>Copyright © 2023  –  Eximho Private Limited  –  All Rights Reserved.</h6>
                </div>

            </footer >

        </div >
    );
}

