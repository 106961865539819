import './App.css';
import React from "react";

import webb_develop from "./img/webb-develop.png"
import webdevlopmentpic from "./img/webdevlopmentpic.png"
import custom_c_development from "./img/custom-c-development.png"
import module from "./img/module.png"
import online_shopping21 from "./img/online-shopping21.png"
import cmss from "./img/cmss.png"
import mobile_app from "./img/mobile-app.png"
import erp from "./img/erp.png"
import Navigation from './Navigation';
import Footer from './Footer';

export default function Home() {
  return (
    <div className='Home'>
      <Navigation />
      <div class="home-header">
        <div class="area" >
          <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 left-content">
                <h4>A Certified Website Development Company</h4>
                <h3>Website Development Company
                </h3>
                <h2>Start Designs is an emerging web development company. We providing services for web design
                  and
                  development at an affordable cost. We have an in-house team of experienced and professional
                  web
                  developers that build your business online using newly coming technologies.
                </h2>
                <div class="button-header">
                  <button class="btn-1"><a href="">Contact Support</a></button>
                  <button class="btn-2"><a href="">Start Project</a></button>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 home-head-img">
                <img src={webb_develop} alt="" />
              </div>
            </div>
          </div>
        </div >
      </div>
      <div class="custom-web-developemnt">
        <div class="container">
          <h1 class="c-w-d">Custom Web Development</h1>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 section-1">
              <img src={webdevlopmentpic} alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 section-1">
              <div class="head-1">
                <h4>One Palace for Complete Web Development Solutions</h4>
                <p>As a reputed website development agency we are providing solutions that empower your
                  business globally and help to reach your business goals. Our team of dedicated web
                  developers has the experience of working for Real Estate, Fashion, Hospitality,
                  Education, E-Commerce, Auto Mobile, and Finance industry.We successfully delivered the
                  1200 +projects weather it is of e-commerce web development or custom website development
                  for any other industry to more than 700 clients globally. Our team efficiently works
                  with all clients from USA, UK, France, Germany, Canada, India and many other
                  countries.We also have experience as a mobile app development agency that builds mobile
                  fully responsive and functionality applications. We have skilled mobile app developers
                  for both android app development & iPhone app development.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="we-offer-website">
        <div class="container">
          <div class="we-offer-website-head">
            <h5>We Are Providing Every Web Development Service That Your Business Needs.</h5>
            <h3>We Offer Website Development Services</h3>
          </div>
          <div class="card-section">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 cards">
                <div class="cardsed">
                  <div class="img-1">
                    <img src={custom_c_development} alt="" />
                  </div>
                  <h4>Custom Website Development</h4>
                  <p>We are offering custom website design and development services that give your
                    business a
                    new
                    identity on the web. Our skillful web designer and developers are highly
                    professional to
                    build your business website.</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 cards">
                <div class="cardsed">
                  <div class="img-1">
                    <img src={module} alt="" />
                  </div>
                  <h4>WordPress Development</h4>
                  <p>WordPress is the most used open-source CMS that gives you the feature of customizing
                    web development. Wordpress has a wide scope of usability as Blog, Magazine,
                    E-Commerce Website, etc. You can hire dedicated WordPress developer on a flexible
                    time module.</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 cards">
                <div class="cardsed">
                  <div class="img-1">
                    <img src={online_shopping21} alt="" />
                  </div>
                  <h4>eCommerce WebDevelopment</h4>
                  <p>Our eCommerce web developers can create B2B and B2C websites very proficiently. We
                    have the expertise that builds fully responsive eCommerce websites for all platforms
                    like mobile, tablets, desktops, and laptops. You can hire eCommerce developers on an
                    hourly, daily, weekly basis.</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 cards">
                <div class="cardsed">
                  <div class="img-1">
                    <img src={cmss} alt="" />
                  </div>
                  <h4>CMS or SaaS Development </h4>
                  <p>If you need a customized CMS or SaaS according to your business requirements. Then
                    our web developers can build CMS & SaaS as per your need. Our web programmers use
                    technologies like PHP, JAVA, ASP.NET, and Python.</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 cards">
                <div class="cardsed">
                  <div class="img-1">
                    <img src={mobile_app} alt="" />
                  </div>
                  <h4>Web App Development</h4>
                  <p>Our web application developers are capable of build apps on the web to empower your
                    business. Our team has the experience of creating a web application for eCommerce,
                    travel, social media, and real estate industries. Hire our web application
                    developers on an hourly basis.</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12 cards">
                <div class="cardsed">
                  <div class="img-1">
                    <img src={erp} alt="" />
                  </div>
                  <h4>ERP Development</h4>
                  <p>We develop ERP software for different industries. Our web programmers build
                    Enterprise Resource Planning(ERP) system that helps you to manage business planning,
                    inventory, human resource management, and finance management. We have experienced
                    team of web programmers to hire at an affordable cost.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
}

