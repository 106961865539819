import './App.css';
import React, { useEffect } from "react";
import contact from "./img/Contact-Us.png"
import map from "./img/map.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './Footer';
import Navigation from './Navigation';
function Contact() {
  useEffect(() => {
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)
  }, []);
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="Contact">
      <Navigation />
      <div className='Contact-home'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 servicess-contents'>
              <div className='services-content'>
                <div className='about-head-content' data-aos="fade-right" data-aos-duration="1500">
                  <h3>We Build Amazing Web Applications</h3>
                  <p>Web Design & Development, Digital Marketing, SEO & SEM.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='services-img'>
                <img data-aos="fade-left" data-aos-duration="1500" src={contact} />
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='contact-card'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='contact-card-1'>
                <div className='card' data-aos="fade-right" data-aos-duration="1500">
                  <a target={'_blank'} href='https://goo.gl/maps/a1j9tsduNCBjiBd46'>
                    <i class="fa-solid fa-location-dot"></i><br />
                    <h4>Head Office</h4>
                    <p>
                      No.36,1st Floor,<br />
                      Medavakkam Main Road,<br />
                      Madipakkam, Chennai - 600091<br />
                      Near Madipakkam Kumaran Theatre
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='contact-card-1'>
                <div className='card' data-aos="fade-up" data-aos-duration="1500">
                  <a target={'_blank'} href="mailto:raaassociate2022@gmail.com">
                    <i class="fa-solid fa-envelope"></i><br />
                    <h4>Email Us</h4>
                    <p>eximhoprivateltd@gmail.com</p></a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <div className='contact-card-1'>
                <div className='card' data-aos="fade-left" data-aos-duration="1500">
                  <a target={'_blank'} href="tel:9380093808">
                    <i class="fa-solid fa-phone"></i><br />
                    <h4>Call Us</h4>
                    <p>Phone :+91-93800 93808</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='map-form'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='map' data-aos="fade-right" data-aos-duration="1500">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d291.0714420177258!2d80.19084724573189!3d12.971794924779466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525da7c8aff331%3A0xf6ae9fd413209467!2sEximho%20Private%20Limited!5e0!3m2!1sen!2sin!4v1682920087287!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <form data-aos="fade-left" data-aos-duration="1500">
                <input size="1" type="text" name="form_fields[name]" id="form-field-name" class="elementor-field elementor-size-lg elementor-field-textual" placeholder="Name" required="required" aria-required="true" /><br />
                <input size="1" type="tel" name="form_fields[field_795bf18]" id="form-field-field_795bf18" class="elementor-field elementor-size-lg elementor-field-textual" placeholder="Phone" required="required" aria-required="true" pattern="[0-9()#&amp;+*-=.]+" title="Only numbers and phone characters (#, -, *, etc) are accepted." /><br />
                <input size="1" type="email" name="form_fields[email]" id="form-field-email" class="elementor-field elementor-size-lg elementor-field-textual" placeholder="Email" required="required" aria-required="true" /><br />
                <input size="1" type="text" name="form_fields[field_db9e67b]" id="form-field-field_db9e67b" class="elementor-field elementor-size-lg elementor-field-textual" placeholder="Company Name" required="required" aria-required="true" /><br />
                <input size="1" type="text" name="form_fields[field_4d13937]" id="form-field-field_4d13937" class="elementor-field elementor-size-lg elementor-field-textual" placeholder="Required Service" required="required" aria-required="true" /><br />
                <button type="submit" class="elementor-button elementor-size-lg"> <span> <span class=" elementor-button-icon"> </span> <span class="elementor-button-text">Submit</span> </span> </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}
export default Contact;
