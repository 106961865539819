import React from "react";
import {
  // BrowserRouter,
  Routes,
  Route,
  HashRouter
} from "react-router-dom";
import Navigation from "./Navigation";
import Footer from "./Footer";
import Home from "./Home";
import Contact from "./Contact";
import About from "./About";
import Services from "./Services";

export default function Routeslist() {

  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/Navigation" element={<Navigation />} />
          <Route path="/Footer" element={<Footer />} />
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>

      </HashRouter>
    </div>
  )
}